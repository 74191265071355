import dayjs from "dayjs";



export function decimal(value, digits = 2, options) {
    if(!value) {
        return value;
    }

    return value.toLocaleString('de-DE', {maximumFractionDigits: digits,  ...options});
}

export function date(date: string | null | Date, format: string = "DD.MM.YYYY") {
    return date ? dayjs(date).format(format) : date;
}
